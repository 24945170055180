#chart {
  display: flex;
  justify-content: center;
  svg {
    overflow: visible;
  }
}

#sequence {
  font-size: 10px;
  width: 100%;
  /*height: 70px;*/
  display: none;
}
@media (max-width: 750px) {
  #sequence {
    font-size: 10px;
  }
}

#legend {
  padding: 10px 0 0 3px;
}

#chart {
  position: relative;
}

#chart path {
  stroke: #000000;
  stroke-width: 0.5px;
  //box-shadow: true;
}

#explanation {
  position: absolute;
  text-align: center;
  color: darkgrey;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 35%;
}
 @media (min-width: 420px) {
  #explanation{
    width: 20%;
  }
}
@media (min-width: 992px) {
  #explanation{
    width: 35%;
  }
}
#percentage {
  font-size: calc(28px + (36 - 28) * ((100vw - 320px) / 960));
  font-weight: 700;
  display: block;
}
#explanation-text {
  font-size: calc(12px + (20 - 14) * ((100vw - 320px) / 960));
  font-weight: 400;
  color: #514f61;
  display: block;
}
#explanation-desc {
  font-size: calc(8px + (15 - 10) * ((100vw - 320px) / 960));
  font-weight: 200;
  color: #514f61;
  line-height: 1.2;
  display: block;
  font-style: italic;
}
